(function () {
    'use strict';

    angular
        .module('consoleApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('application', {
            parent: 'app',
            url: '/application/{appId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_APP_MANAGER', 'ROLE_SUPPORT', 'ROLE_DEVELOPER', 'ROLE_SALES', 'ROLE_REPORT'],
                pageTitle: 'global.menu.admin.application'
            },
            views: {
                'content@': {
                    templateUrl: 'app/application/application.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                appId: appId,
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('detail');
                    return $translate.refresh();
                }]
            }
        }).state('application.preview', {
            url: '/preview?appId&token',
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/application/application-preview-dialog.html',
                    controller: 'ApplicationPreviewController',
                    controllerAs: 'vm',
                    openedClass: 'iframe-opened',
                    windowClass: 'iframe-window',
                    backdrop: 'static',
                    keyboard: false,
                    params: {appId: null, token: null},
                    resolve: {
                        appId: appId,
                        token: token
                    }
                }).result.then(function () {
                    $state.go('^');
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }

    appId.$inject = ['$stateParams'];

    function appId($stateParams) {
        return $stateParams.appId;
    }

    token.$inject = ['$stateParams'];

    function token($stateParams) {
        return $stateParams.token;
    }
})();
