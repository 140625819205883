(function () {
    'use strict';

    angular
        .module('consoleApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('livetaskdetail', {
            parent: 'admin',
            url: '/live/{channel}/{appId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_APP_MANAGER', 'ROLE_SUPPORT', 'ROLE_DEVELOPER', 'ROLE_SALES', 'ROLE_REPORT'],
                pageTitle: 'global.menu.admin.taskdetail'
            },
            views: {
                'content@': {
                    templateUrl: 'app/application/livestoredetail.html',
                    controller: 'LiveStoreDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                channel: channel,
                appId: appId,
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('detail');
                    return $translate.refresh();
                }]
            }
        });
    }

    channel.$inject = ['$stateParams'];

    /* @ngInject */
    function channel($stateParams) {
        return $stateParams.channel;
    }

    appId.$inject = ['$stateParams'];

    /* @ngInject */
    function appId($stateParams) {
        return $stateParams.appId;
    }
})();
