(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('AppController', AppController);

    AppController.$inject = ['$rootScope'];

    function AppController($rootScope) {
        var vm = this;
        Pace.restart();

        $rootScope.$on('$viewContentLoaded', function () {
            $.Pages.init();
            // Pace.restart();
        });
    }
})();


