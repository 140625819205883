(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', '$rootScope'];

    function SettingsController(Principal, Auth, JhiLanguageService, $translate, $rootScope) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        $rootScope.currentMenu = "settings";
        vm.changePassword = changePassword;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
        });

        function save() {
            Auth.updateAccount(vm.settingsAccount).then(function () {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function (account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function (current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
                $('.page-content-wrapper').pgNotification({
                    style: 'circle',
                    title: $translate.instant('settings.messages.saveSuccessTitle'),
                    message: $translate.instant('settings.messages.saveSuccess'),
                    position: 'top-right',
                    timeout: 5000,
                    type: 'info'
                }).show();
            }).catch(function () {
                $('.page-content-wrapper').pgNotification({
                    style: 'circle',
                    title: $translate.instant('settings.messages.saveErrorTitle'),
                    message: $translate.instant('settings.messages.saveError'),
                    position: 'top-right',
                    timeout: 5000,
                    type: 'info'
                }).show();
            });
        }

        function changePassword() {
            if (vm.password !== vm.confirmPassword) {
                $('.page-content-wrapper').pgNotification({
                    style: 'circle',
                    title: $translate.instant('settings.messages.changePasswordDontMatchTitle'),
                    message: $translate.instant('settings.messages.changePasswordDontMatch'),
                    position: 'top-right',
                    timeout: 5000,
                    type: 'info'
                }).show();
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    $('.page-content-wrapper').pgNotification({
                        style: 'circle',
                        title: $translate.instant('settings.messages.changePasswordSuccessTitle'),
                        message: $translate.instant('settings.messages.changePasswordSuccess'),
                        position: 'top-right',
                        timeout: 5000,
                        type: 'info'
                    }).show();
                }).catch(function () {
                    $('.page-content-wrapper').pgNotification({
                        style: 'circle',
                        title: $translate.instant('settings.messages.changePasswordErrorTitle'),
                        message: $translate.instant('settings.messages.changePasswordError'),
                        position: 'top-right',
                        timeout: 5000,
                        type: 'info'
                    }).show();
                });
            }
        }
    }
})();
