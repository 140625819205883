(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('ApplicationController', ApplicationController);

    ApplicationController.$inject = ['$scope', '$rootScope', '$controller', 'Api', 'appId', '$localStorage', 'Upload'
        , 'API_HOST'];

    function ApplicationController($scope, $rootScope, $controller, Api, appId, $localStorage, Upload, API_HOST) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.appId = appId;
        $rootScope.appId = vm.appId;
        vm.appInfo;
        vm.appInfoCopy;
        vm.saveAppConfig = saveAppConfig;
        vm.uploadCert = uploadCert;
        vm.openCalendarSandbox = openCalendarSandbox;
        vm.openCalendarProd = openCalendarProd;
        vm.datepickerOptions = {
            showWeeks: false,
            startingDay: 1
        };
        vm.userByAppId = [];
        vm.androidVersions = [];
        vm.iosVersions = [];
        vm.loaded = false;

        init();

        function init() {
            Api.applicationInformation({appId: vm.appId}, onSuccess, onFail);
        }

        function calculateShownFlag() {
            var waitingForStoreClientInfos = vm.appInfo.waitingForStoreClientInfos;
            if (waitingForStoreClientInfos && waitingForStoreClientInfos.length > 0) {
                var version3 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'IOS';
                });
                if (version3) {
                    vm.showIosTaskTab = true;
                }
                var version4 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'ANDROID';
                });
                if (version4) {
                    vm.showAndroidTaskTab = true;
                }
            }
        }

        function onSuccess(appInfo) {
            vm.appInfo = appInfo;
            vm.loaded = true;
            calculateShownFlag();
            $localStorage.appInfo = appInfo;
            vm.appInfoCopy = angular.copy(vm.appInfo);
            if (vm.appInfoCopy.notificationConfig) {
                if (vm.appInfoCopy.notificationConfig.certificationExpiration) {
                    vm.appInfoCopy.notificationConfig.certificationExpiration = new Date(vm.appInfoCopy.notificationConfig.certificationExpiration);
                }
                if (vm.appInfoCopy.notificationConfig.sandboxCertificationExpiration) {
                    vm.appInfoCopy.notificationConfig.sandboxCertificationExpiration = new Date(vm.appInfoCopy.notificationConfig.sandboxCertificationExpiration);
                }
            }
            if (vm.appInfo.versionList) {
                vm.iosVersions = vm.appInfo.versionList.iosVersions;
                vm.androidVersions = vm.appInfo.versionList.androidVersions;
            }

        }

        function loadIosTags() {
            return vm.iosVersions;
        }

        function loadAndroidTags() {
            return vm.androidVersions;
        }

        function onFail() {
            delete $localStorage.appInfo;
        }

        function saveAppConfig() {
            var req = {
                applicationId: parseInt(vm.appId),
                bundle: vm.appInfoCopy.bundle,
                androidStoreLink: vm.appInfoCopy.androidShareLink,
                iosStoreLink: vm.appInfoCopy.iosShareLink,
                primaryColor: vm.appInfoCopy.primaryColor,
                secondaryColor: vm.appInfoCopy.secondaryColor
            };
            if (vm.appInfoCopy.notificationConfig) {
                req.prodCertPath = vm.appInfoCopy.notificationConfig.certificationPath;
                req.prodCertExpiration = formatDate(vm.appInfoCopy.notificationConfig.certificationExpiration);
                req.sandboxCertPath = vm.appInfoCopy.notificationConfig.sandboxCertificationPath;
                req.sandboxCertExpiration = formatDate(vm.appInfoCopy.notificationConfig.sandboxCertificationExpiration);
                req.gcmSenderId = vm.appInfoCopy.notificationConfig.gcmSenderId;
                req.fcmServerKey = vm.appInfoCopy.notificationConfig.fcmServerKey;
            }
            var ccav = [], cciv = [], fuiv = [], fuav = [];
            for (var i = 0; i < vm.appInfoCopy.clearCacheAndroid.versions.length; i++) {
                ccav.push(vm.appInfoCopy.clearCacheAndroid.versions[i].text);
            }
            for (var i = 0; i < vm.appInfoCopy.clearCacheIos.versions.length; i++) {
                cciv.push(vm.appInfoCopy.clearCacheIos.versions[i].text);
            }
            for (var i = 0; i < vm.appInfoCopy.forceUpdateIos.versions.length; i++) {
                fuiv.push(vm.appInfoCopy.forceUpdateIos.versions[i].text);
            }
            for (var i = 0; i < vm.appInfoCopy.forceUpdateAndroid.versions.length; i++) {
                fuav.push(vm.appInfoCopy.forceUpdateAndroid.versions[i].text);
            }
            req.clearCacheAndroidVersions = ccav;
            req.clearCacheIosVersions = cciv;
            req.forceUpdateIosVersions = fuiv;
            req.forceUpdateAndroidVersions = fuav;
            Api.updateApplicationConfig({}, req, function (resp) {
                init();
            });
        }

        function uploadCert(file, prod) {
            Upload.upload({
                url: API_HOST + '/console/upload/certificate',
                file: file
            }).then(function (response) {
                if (!vm.appInfoCopy.notificationConfig) {
                    vm.appInfoCopy.notificationConfig = {};
                }
                if (prod) {
                    vm.appInfoCopy.notificationConfig.certificationPath = response.data.path;
                } else {

                    vm.appInfoCopy.notificationConfig.sandboxCertificationPath = response.data.path;
                }
            });
        }

        function openCalendarProd(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenProd = true;
        }

        function openCalendarSandbox(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenSandbox = true;
        }

        function formatDate(d) {
            if (!d) {
                return "";
            }
            var month = d.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            var date = d.getDate();
            if (date < 10) {
                date = "0" + date;
            }
            return d.getFullYear() + "-" + month + "-" + date;
        }

    }
})();
