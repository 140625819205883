(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('ChecklistController', ChecklistController);

    ChecklistController.$inject = ['$scope', '$rootScope', '$controller', 'Checklist', '$state', '$translate'];

    function ChecklistController($scope, $rootScope, $controller, Checklist, $state, $translate) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "checklist";
        vm.statuses = [
            {"key": "INITIAL", "value": $translate.instant('checklist.statusList.INITIAL')},
            {"key": "IN_REVIEW", "value": $translate.instant('checklist.statusList.IN_REVIEW')},
            {"key": "PROCESSING_FOR_STORE", "value": $translate.instant('checklist.statusList.PROCESSING_FOR_STORE')},
            {"key": "WAITING_FOR_STORE", "value": $translate.instant('checklist.statusList.WAITING_FOR_STORE')},
            {"key": "REJECTED", "value": $translate.instant('checklist.statusList.REJECTED')},
            {"key": "COMPLETE", "value": $translate.instant('checklist.statusList.COMPLETE')}
        ];
        vm.channels = ["IOS", "ANDROID", "BOTH"];
        vm.selectedStatus = "INITIAL";
        init();
        vm.checklistByStatus;
        vm.tableData = [];
        vm.newItem = {};
        vm.selectedItem = {};
        vm.updateTableData = updateTableData;
        vm.openNewItemModal = openNewItemModal;
        vm.saveNewItem = saveNewItem;
        vm.selectItem = selectItem;
        vm.updateNewItem = updateNewItem;
        vm.addRemoveFromList = addRemoveFromList;
        vm.checkedItems = [];
        vm.removeSelected = removeSelected;
        vm.setPristine = setPristine;

        function init() {
            Checklist.query({}, onSuccess, onFail);
        }

        function selectItem(item) {
            vm.selectedItem = angular.copy(item);
            var modalElem = $('#updateChecklist');
            modalElem.modal('show');
            modalElem.children('.modal-dialog').addClass('modal-lg');
        }

        function updateTableData() {
            vm.checkedItems = [];
            if (vm.checklistByStatus) {
                vm.tableData = vm.checklistByStatus[vm.selectedStatus];
            }
        }

        function onSuccess(response) {
            if (response) {
                vm.checklistByStatus = response;
                vm.tableData = response[vm.selectedStatus];
            }
        }

        function onFail() {

        }

        function openNewItemModal() {
            vm.newItem = {mandatory: true};
            var modalElem = $('#addChecklist');
            modalElem.modal('show');
            modalElem.children('.modal-dialog').addClass('modal-lg');
        }

        function setPristine(form) {
            form.$setPristine();
        }

        function saveNewItem(form) {
            setPristine(form);
            vm.newItem.status = vm.selectedStatus;
            Checklist.save(vm.newItem, onSaveSuccess, onSaveFail);
        }

        function updateNewItem(form) {
            setPristine(form);
            Checklist.update(vm.selectedItem, onUpdateSuccess, onUpdateFail);
        }

        function onSaveSuccess(response) {
            init();
            $('#addChecklist').modal('hide');
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('checklist.messages.saveSuccessTitle'),
                message: $translate.instant('checklist.messages.saveSuccess'),
                position: 'top-right',
                timeout: 5000,
                type: 'info'
            }).show();
        }

        function onSaveFail() {
            $('#updateChecklist').modal('hide');
        }

        function onUpdateSuccess(response) {
            init();
            $('#updateChecklist').modal('hide');
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('checklist.messages.updateSuccessTitle'),
                message: $translate.instant('checklist.messages.updateSuccess'),
                position: 'top-right',
                timeout: 5000,
                type: 'info'
            }).show();
        }

        function onUpdateFail() {
            $('#updateChecklist').modal('hide');
        }

        function addRemoveFromList(id) {
            var index = vm.checkedItems.indexOf(id);
            if (index > -1) {
                vm.checkedItems.splice(index, 1);
            } else {
                vm.checkedItems.push(id);
            }
        }

        function removeSelected() {
            Checklist.deleteItems({}, vm.checkedItems, onRemoveSuccess, onRemoveError)
        }

        function onRemoveSuccess() {
            init();
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('checklist.messages.removeSuccessTitle'),
                message: $translate.instant('checklist.messages.removeSuccess'),
                position: 'top-right',
                timeout: 5000,
                type: 'info'
            }).show();
        }

        function onRemoveError() {

        }

    }
})();
