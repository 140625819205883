(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('AndroidTaskController', AndroidTaskController);

    AndroidTaskController.$inject = ['$scope', '$rootScope', '$controller', 'Principal', '$localStorage', 'Api', 'User',
        'Checklist', '$state', '$uibModal'];

    function AndroidTaskController($scope, $rootScope, $controller, Principal, $localStorage, Api, User, Checklist,
                                   $state, $uibModal) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.channel = 'android';
        vm.appId;
        vm.appInfo;
        vm.task;
        vm.account;
        vm.takeOnProcess = takeOnProcess;
        vm.openMoveProcessModal = openMoveProcessModal;
        vm.moveProcess = moveProcess;
        vm.comment = "";
        vm.checklists = [];
        vm.logs = [];
        vm.checkItem = checkItem;
        vm.selectItem = selectItem;
        vm.getClass = getClass;
        vm.cantMove = false;

        init();

        function init() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
            vm.appInfo = $localStorage.appInfo;
            vm.appId = $localStorage.appInfo.applicationId;
            Api.taskByChannelAndAppId({appId: vm.appId, channel: vm.channel}, onSuccess, onFail);
            // Api.applicationInformation({appId: vm.appId}, onSuccess, onFail);
        }

        function onSuccess(task) {
            if (task && task.channelProcessStatus) {
                vm.task = task;
                getUsername(vm.task.assignedUserId);
                Checklist.getByStatus({status: task.channelProcessStatus}, onChecklistResultSuccess, onChecklistResultFail);
            } else {
                var clientInfo = $localStorage.appInfo.waitingForStoreClientInfos.find(function (item) {
                    return item.channel == vm.channel.toUpperCase();
                });
                // var req = {
                //     applicationId: vm.appId,
                //     clientVersion: clientInfo.version
                // };
                // UploadService.migrateFiles({}, req);
                $state.go($state.current, {"appId": vm.appId}, {reload: true});
            }
        }

        function onChecklistResultSuccess(data) {
            vm.checklists = data.filter(function (item) {
                return item.channel == 'ANDROID' || item.channel == 'BOTH';
            });
            getLogs();
        }

        function onChecklistResultFail() {

        }

        function onFail() {

        }

        function getUsername(userId) {
            if (userId) {
                User.getUserById({id: userId}, onUsernameSuccess, onUsernameFail)
            }
        }

        function onUsernameSuccess(resp) {
            vm.task.assignedUserName = resp.login;
        }

        function onUsernameFail() {

        }

        function takeOnProcess() {
            var req = {
                processId: vm.task.storeProcessRequestId,
                channel: vm.channel.toUpperCase()
            };
            Api.takeOnWaitingProcess({}, req, onTakeSuccess, onTakeFail);
        }

        function openMoveProcessModal() {
            vm.comment = "";
            var modalElem = $('#moveTaskAndroid');
            modalElem.modal('show');
        }

        function moveProcess() {
            var req = {
                applicationId: parseInt(vm.appId),
                channel: vm.channel.toUpperCase(),
                comment: vm.comment
            };
            Api.proceed({}, req, onMoveSuccess, onMoveFail);
        }

        function onTakeSuccess() {
            vm.task.assignedUserName = vm.account.login;
            vm.task.assignedUserId = vm.account.id;

        }

        function onTakeFail() {

        }

        function onMoveSuccess() {
            $('#moveTaskAndroid').modal('hide');
            init();
        }

        function onMoveFail() {
            $('#moveTaskAndroid').modal('hide');
        }

        function getLogs() {
            Checklist.getLogs({
                taskId: vm.task.storeProcessRequestId,
                status: vm.task.channelProcessStatus,
                channel: vm.channel.toUpperCase()
            }, onLogSuccess, onLogFail);
        }

        function onLogSuccess(response) {
            vm.logs = response;
            for (var i = 0; i < vm.checklists.length; i++) {
                vm.checklists[i].done = false;
            }
            for (var i = 0; i < response.length; i++) {
                var log = response[i];
                var result = vm.checklists.find(function (item) {
                    return item.id == log.cheklistItemId;
                });
                if (result) {
                    result.done = true;
                } else {
                    result.done = false;
                }
            }
            calculateCanMove();
        }

        function calculateCanMove() {
            var mandatories = vm.checklists.filter(function (t) {
                return t.mandatory;
            });
            if (mandatories && mandatories.length > 0) {
                var filtered = mandatories.filter(function (item) {
                    return !item.done;
                });
                if (filtered && filtered.length > 0) {
                    vm.cantMove = true;
                } else {
                    vm.cantMove = false;
                }
            } else {
                vm.cantMove = false;
            }
        }

        function onLogFail() {

        }

        function selectItem(checklist, $event) {
            $event.preventDefault();
            if (!checklist.done) {
                vm.itemWillBeChecked = checklist;
                var instance = $uibModal.open({
                    templateUrl: 'app/application/task.modal.html',
                    controller: 'TaskModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        itemWillBeChecked: function () {
                            return vm.itemWillBeChecked;
                        }
                    }
                }).result.then(function (move) {
                    if (move && move === 'move') {
                        checkItem();
                    }
                });
            }
        }

        function checkItem() {
            Checklist.checkItem({}, {
                cheklistItemId: vm.itemWillBeChecked.id,
                taskId: vm.task.storeProcessRequestId,
                status: vm.task.channelProcessStatus,
                channel: vm.channel.toUpperCase()
            }, function (response) {
                vm.itemWillBeChecked.done = true;
                calculateCanMove();
            }, function () {

            });
        }

        function getClass(checklist) {
            var clazz = "";
            if (checklist.mandatory) {
                clazz = "text-mandatory";
            } else {
                clazz = "text-master";
            }
            if (checklist.done) {
                clazz += " strikethrough";
            }
            return clazz;
        }

    }
})();
