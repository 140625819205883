(function () {
    'use strict';

    angular
        .module('consoleApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        var resourceUrl = 'api/users/';
        var resourceUrlSingle = 'api/users/:login';
        return $resource(resourceUrl, {}, {
            query: {
                url: resourceUrlSingle,
                method: 'GET',
                isArray: true
            },
            get: {
                url: resourceUrlSingle,
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            getUserById: {
                url: resourceUrl + '/:id',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            save: {
                url: resourceUrlSingle,
                method: 'POST'
            },
            update: {
                url: resourceUrlSingle,
                method: 'PUT'
            },
            delete: {
                method: 'DELETE',
                url: resourceUrlSingle
            },
            deleteUsers: {
                method: 'POST',
                url: resourceUrl + 'deleteUsers'
            }
        });
    }
})();
