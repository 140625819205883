(function () {
    'use strict';

    angular
        .module('consoleApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ngTagsInput',
            'ngFileUpload',
            'darthwade.dwLoading'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope'];

    function run(stateHandler, translationHandler, $rootScope) {
        stateHandler.initialize();
        translationHandler.initialize();
        $rootScope.$on('$stateChangeSuccess', function (event, to, toParams, from, fromParams) {
            $rootScope.previousState = from;
        });
    }
})();
