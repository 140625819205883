(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams',
        'paginationConstants', 'JhiLanguageService', '$controller', '$scope', '$rootScope', '$translate'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants,
                                      JhiLanguageService, $controller, $scope, $rootScope, $translate) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        vm.authorities = [{'key': 'ROLE_ADMIN', 'value': $translate.instant('userManagement.roles.ROLE_ADMIN')},
            {'key': 'ROLE_APP_MANAGER', 'value': $translate.instant('userManagement.roles.ROLE_APP_MANAGER')},
            {'key': 'ROLE_DEVELOPER', 'value': $translate.instant('userManagement.roles.ROLE_DEVELOPER')},
            {'key': 'ROLE_SALES', 'value': $translate.instant('userManagement.roles.ROLE_SALES')},
            {'key': 'ROLE_SUPPORT', 'value': $translate.instant('userManagement.roles.ROLE_SUPPORT')},
            {'key': 'ROLE_REPORT', 'value': $translate.instant('userManagement.roles.ROLE_REPORT')}];
        vm.statuses = [
            {"key": "ACTIVATED", "value": $translate.instant('userManagement.statusList.ACTIVATED')},
            {"key": "DELETED", "value": $translate.instant('userManagement.statusList.DELETED')},
            {"key": "SUSPENDED", "value": $translate.instant('userManagement.statusList.SUSPENDED')},
            {"key": "NEW", "value": $translate.instant('userManagement.statusList.NEW')}
        ];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.openAddUserModal = openAddUserModal;
        vm.save = save;
        vm.update = update;
        vm.newUser = null;
        vm.removeSelected = removeSelected;
        vm.checkedUsers = [];
        vm.addRemoveFromList = addRemoveFromList;
        vm.selectUser = selectUser;
        vm.setPristine = setPristine;
        vm.copyUser = {};
        vm.form;
        vm.form2 = "form2";
        $rootScope.currentMenu = "userManagement";

        vm.loadAll();
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });

        function setActive(user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function loadAll() {
            User.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data.filter(function (item) {
                return item.login !== vm.currentAccount.login;
            });
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear() {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function openAddUserModal() {
            vm.newUser = {};
            var modalElem = $('#addUserModal');
            modalElem.modal('show');
            modalElem.children('.modal-dialog').addClass('modal-lg');
        }

        function onSaveSuccess(result) {
            vm.loadAll();
            $('#addUserModal').modal('hide');
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('userManagement.messages.saveSuccessTitle'),
                message: $translate.instant('userManagement.messages.saveSuccess'),
                position: 'top-right',
                timeout: 5000,
                type: 'info'
            }).show();
        }

        function onUpdateSuccess(result) {
            vm.loadAll();
            $('#updateUserModal').modal('hide');
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('userManagement.messages.updateSuccessTitle'),
                message: $translate.instant('userManagement.messages.updateSuccess'),
                position: 'top-right',
                timeout: 5000,
                type: 'info'
            }).show();
        }

        function onSaveError() {
            $('#addUserModal').modal('hide');
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('userManagement.messages.error.failSaveTitle'),
                message: $translate.instant('userManagement.messages.error.failSave'),
                position: 'top-right',
                timeout: 5000,
                type: 'warn'
            }).show();
        }

        function onUpdateError() {
            $('#updateUserModal').modal('hide');
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('userManagement.messages.error.failUpdateTitle'),
                message: $translate.instant('userManagement.messages.error.failUpdate'),
                position: 'top-right',
                timeout: 5000,
                type: 'warn'
            }).show();
        }

        function onRemoveSuccess(result) {
            vm.loadAll();
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('userManagement.messages.removeSuccessTitle'),
                message: $translate.instant('userManagement.messages.removeSuccess'),
                position: 'top-right',
                timeout: 5000,
                type: 'info'
            }).show();
        }

        function onRemoveError() {
            $('.page-content-wrapper').pgNotification({
                style: 'circle',
                title: $translate.instant('userManagement.messages.error.failRemoveTitle'),
                message: $translate.instant('userManagement.messages.error.failRemove'),
                position: 'top-right',
                timeout: 5000,
                type: 'warn'
            }).show();
        }

        function selectUser(user) {
            vm.copyUser = angular.copy(user);
            var modalElem = $('#updateUserModal');
            modalElem.modal('show');
            modalElem.children('.modal-dialog').addClass('modal-lg');
        }

        function setPristine(form) {
            form.$setPristine();
        }

        function save(form) {
            setPristine(form);
            vm.newUser.userStatus = "NEW";
            User.save(vm.newUser, onSaveSuccess, onSaveError);
        }

        function update() {
            User.update(vm.copyUser, onUpdateSuccess, onUpdateError);
        }

        function removeSelected() {
            User.deleteUsers({}, vm.checkedUsers, onRemoveSuccess, onRemoveError)
        }

        function addRemoveFromList(id) {
            var index = vm.checkedUsers.indexOf(id);
            if (index > -1) {
                vm.checkedUsers.splice(index, 1);
            } else {
                vm.checkedUsers.push(id);
            }
        }

    }
})();
