(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('ApplicationPreviewController', ApplicationPreviewController);

    ApplicationPreviewController.$inject = ['$uibModalInstance', 'PROJECT_HOST', '$sce', '$stateParams'];

    function ApplicationPreviewController($uibModalInstance, PROJECT_HOST, $sce, $stateParams) {
        var vm = this;

        vm.token = $stateParams.token;
        vm.appId = $stateParams.appId;
        vm.close = close;

        init();

        function init() {
            var host = PROJECT_HOST + "?q=" + Date.now() + "&token=" + vm.token + "";
            vm.projectsUrl = $sce.trustAsResourceUrl(host);
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
