(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('AppUsersController', AppUsersController);

    AppUsersController.$inject = ['$scope', '$rootScope', '$controller', '$localStorage', 'Api', 'ApiToken', '$state', 'Principal'];

    function AppUsersController($scope, $rootScope, $controller, $localStorage, Api, ApiToken, $state, Principal) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.viewAs = viewAs;
        init();

        function init() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
            vm.appInfo = $localStorage.appInfo;
            vm.appId = $localStorage.appInfo.applicationId;
            Api.userByAppId({appId: vm.appId}, function (response) {
                vm.userByAppId = response;
            });
        }

        function viewAs(user) {
            ApiToken.get({userId: user.id, applicationId: vm.appId, authority: user.authority}, function (response) {
                $state.go('application.preview', {token: response.token, appId: vm.appId});
            });
        }

    }
})();
