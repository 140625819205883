(function () {
    'use strict';

    angular
        .module('consoleApp')
        .factory('Api', Api);

    Api.$inject = ['$resource', 'API_HOST'];

    function Api($resource, API_HOST) {
        var resourceUrl = API_HOST + '/console';
        return $resource(resourceUrl, {}, {
            applications: {
                url: resourceUrl + '/applications',
                method: 'GET',
                isArray: true
            },
            notAssignedProcesses: {
                url: resourceUrl + '/notAssignedProcesses',
                method: 'GET',
                isArray: true
            },
            myProcesses: {
                url: resourceUrl + '/myProcesses',
                method: 'GET',
                isArray: true
            },
            takeOnWaitingProcess: {
                url: resourceUrl + '/takeOnWaitingProcess',
                method: 'POST'
            },
            applicationInformation: {
                url: resourceUrl + '/application/:appId',
                method: 'GET'
            },
            userByAppId: {
                url: resourceUrl + '/users/:appId',
                method: 'GET',
                isArray: true
            },
            taskByChannelAndAppId: {
                url: resourceUrl + '/task/:channel/:appId',
                method: 'GET'
            },
            taskLogByAppId: {
                url: resourceUrl + '/tasklog/:appId',
                method: 'GET',
                isArray: true
            },
            proceed: {
                url: resourceUrl + '/proceed',
                method: 'POST'
            },
            updateApplicationConfig: {
                url: resourceUrl + '/updateApplicationConfig',
                method: 'POST'
            }
        });
    }
})();
