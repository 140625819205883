(function () {
    'use strict';

    angular.module('consoleApp')
        .controller('TaskModalController', TaskModalController);

    TaskModalController.$inject = ['$uibModalInstance', 'itemWillBeChecked'];

    function TaskModalController($uibModalInstance, itemWillBeChecked) {
        var vm = this;

        vm.cancel = cancel;
        vm.move = move;
        vm.itemWillBeChecked = itemWillBeChecked;

        function move() {
            $uibModalInstance.close('move');
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
