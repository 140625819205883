(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('PendingStoreDetailController', PendingStoreDetailController);

    PendingStoreDetailController.$inject = ['$scope', '$rootScope', '$controller', 'Api', 'appId', 'channel', '$localStorage',
        'Upload', 'UploadService', '$translate', '$loading', '$timeout'];

    function PendingStoreDetailController($scope, $rootScope, $controller, Api, appId, channel, $localStorage, Upload,
                                          UploadService, $translate, $loading, $timeout) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.channel = channel;
        vm.appId = appId;
        $rootScope.appId = vm.appId;
        vm.appInfo;
        vm.appInfoCopy;
        vm.selectedLang;
        vm.selectableLanguage = [];
        vm.selectedLangText = "";
        vm.selectLanguage = selectLanguage;
        vm.uploadFile = {};
        vm.images = {};
        vm.uploadFile = uploadFile;
        vm.fieldShow = fieldShow;

        init();

        function init() {
            if ($localStorage.appInfo) {
                vm.appInfo = $localStorage.appInfo;
                vm.info = $localStorage.appInfo.draftStoreInfo;
                vm.selectedLang = $localStorage.appInfo.defaultApplicationLanguage.language;
                var clientInfo = $localStorage.appInfo.waitingForStoreClientInfos.find(function (item) {
                    return item.channel == vm.channel.toUpperCase();
                });
                vm.info.storeVersion = clientInfo.version;
                vm.info.theme = $localStorage.appInfo.themeCode;
                vm.info.packageName = $localStorage.appInfo.bundle;
                UploadService.assets({
                    applicationId: vm.appId,
                    clientVersion: clientInfo.version
                }, onSuccessAssets, onFailAssets);
                calculateShownFlag();
                setLanguages(vm.appInfo.contentLanguages);
                getKeywords();
                vm.loaded = true;
            } else {
                Api.applicationInformation({appId: vm.appId}, onSuccess, onFail);
            }
        }

        function onSuccessAssets(data) {
            vm.images = data;
        }

        function onFailAssets() {

        }

        function calculateShownFlag() {
            var waitingForStoreClientInfos = vm.appInfo.waitingForStoreClientInfos;
            if (waitingForStoreClientInfos && waitingForStoreClientInfos.length > 0) {
                var version3 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'IOS';
                });
                if (version3) {
                    vm.showIosTaskTab = true;
                }
                var version4 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'ANDROID';
                });
                if (version4) {
                    vm.showAndroidTaskTab = true;
                }
            }
        }

        function onSuccess(appInfo) {
            vm.appInfo = appInfo;
            calculateShownFlag();
            $localStorage.appInfo = appInfo;
            vm.appInfoCopy = angular.copy(vm.appInfo);
            debugger;
            vm.selectedLang = appInfo.defaultApplicationLanguage.language;
            vm.info = appInfo.draftStoreInfo;
            var clientInfo = appInfo.waitingForStoreClientInfos.find(function (item) {
                return item.channel == vm.channel.toUpperCase();
            });
            vm.info.storeVersion = clientInfo.version;
            vm.info.theme = appInfo.themeCode;
            vm.info.packageName = appInfo.bundle;
            UploadService.assets({
                applicationId: vm.appId,
                clientVersion: clientInfo.version
            }, onSuccessAssets, onFailAssets);
            setLanguages(appInfo.contentLanguages);
            getKeywords();
            vm.loaded = true;
        }

        function setLanguages(languages) {
            var activeLangs = [];
            for (var keyName in vm.info.name) {
                activeLangs.push(keyName);
            }
            for (var i = 0; i < languages.length; i++) {
                var language = languages[i].language;
                if (activeLangs.indexOf(language.language) > -1) {
                    vm.selectableLanguage.push(language);
                }
                if (language.language == vm.selectedLang) {
                    vm.selectedLangText = language.description;
                }
            }
        }

        function onFail() {
            delete $localStorage.appInfo;
        }

        function selectLanguage(lang) {
            setKeywords();
            vm.selectedLang = lang.language;
            vm.selectedLangText = lang.description;
            getKeywords();
        }

        function getKeywords() {
            vm.info.dummyTags = [];
            if (vm.info.keywords) {
                if (vm.info.keywords[vm.selectedLang]) {
                    for (var i = 0; i < vm.info.keywords[vm.selectedLang].length; i++) {
                        vm.info.dummyTags.push({text: vm.info.keywords[vm.selectedLang][i]});
                    }
                } else {
                    vm.info.keywords[vm.selectedLang] = [];
                }
            } else {
                vm.info.keywords = {};
                vm.info.keywords[vm.selectedLang] = [];
            }
        }

        function setKeywords() {
            if (vm.info.dummyTags) {
                vm.info.keywords[vm.selectedLang] = [];
                for (var i = 0; i < vm.info.dummyTags.length; i++) {
                    vm.info.keywords[vm.selectedLang].push(vm.info.dummyTags[i].text);
                }
            }
        }

        function uploadFile(file, uploadType) {
            if (file) {
                $loading.start('uploadPreloader');
                Upload.upload({
                    url: 'api/upload/file',
                    data: {
                        file: file,
                        'applicationId': vm.appId,
                        'clientVersion': vm.info.storeVersion,
                        'uploadType': uploadType,
                        'lang': vm.selectedLang
                    }
                }).then(function (resp) {
                    $timeout(function () {
                        $loading.finish('uploadPreloader');
                    }, 200);

                    if (!vm.images[uploadType]) {
                        vm.images[uploadType] = [];
                    }
                    vm.images[uploadType].push(resp.data);
                    $('.page-content-wrapper').pgNotification({
                        style: 'circle',
                        title: $translate.instant('detail.messages.uploadSuccessTitle'),
                        message: $translate.instant('checklist.messages.uploadSuccess'),
                        position: 'top-right',
                        timeout: 5000,
                        type: 'info'
                    }).show();
                });
            }
        }

        function fieldShow(type) {
            if (vm.images[type]) {
                var list = vm.images[type].filter(function (item) {
                    return item.lang == vm.selectedLang;
                });
                if (list.length > 0) {
                    if (type == 'IPHONE_VIDEO' || type == 'IPAD_VIDEO' || type == 'IOS_APP_ICON'
                        || type == 'ANDROID_BANNER_WITHOUT_ALPHA' || type == 'ANDROID_ICON_WITH_ALPHA') {
                        if (list.length == 1) {
                            return false;
                        }
                    } else {
                        // if (list.length < 5) {
                        return true;
                        // } else {
                        //     return false;
                        // }
                    }
                } else {
                    return true;
                }
            }
            return true;
        }

    }
})();
