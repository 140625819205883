(function () {
    'use strict';

    angular
        .module('consoleApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage', '$loading', '$timeout'];

    function authInterceptor($rootScope, $q, $location, $localStorage, $sessionStorage, $loading, $timeout) {
        var service = {
            request: request,
            response: response
        };

        return service;

        function request(config) {
            if (config.method != 'GET') {
                $loading.start('mainPreloader');
            }
            config.headers = config.headers || {};
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        }

        function response(resp) {
            if (resp.config.method != 'GET') {
                $timeout(function () {
                    $loading.finish('mainPreloader');
                }, 200);
            }
            return resp;
        }
    }
})();
