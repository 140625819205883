(function () {
    'use strict';

    angular
        .module('consoleApp')
        .factory('UploadService', UploadService);

    UploadService.$inject = ['$resource'];

    function UploadService($resource) {
        var resourceUrl = 'api/upload';
        return $resource(resourceUrl, {}, {
            assets: {
                url: resourceUrl + '/assets',
                method: 'GET'
            },
            migrateFiles: {
                url: resourceUrl + '/migrateFiles',
                method: 'POST'
            }
        });
    }
})(angular);
