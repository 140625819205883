(function () {
    'use strict';

    angular
        .module('consoleApp')
        .factory('Checklist', Checklist);

    Checklist.$inject = ['$resource'];

    function Checklist($resource) {
        var resourceUrl = 'api/checklist/';
        return $resource(resourceUrl, {}, {
            query: {
                url: resourceUrl + 'query',
                method: 'GET'
            },
            getById: {
                url: resourceUrl + ':id',
                method: 'GET'
            },
            getByStatus: {
                url: resourceUrl + 'get/:status',
                method: 'GET',
                isArray: true
            },
            save: {
                url: resourceUrl + 'save',
                method: 'POST'
            },
            update: {
                url: resourceUrl + 'update',
                method: 'PUT'
            },
            deleteItems: {
                method: 'POST',
                url: resourceUrl + 'delete'
            },
            checkItem: {
                method: 'POST',
                url: resourceUrl + 'checkItem'
            },
            getLogs: {
                url: resourceUrl + 'getLogs',
                method: 'GET',
                isArray: true
            },
            getAllLogs: {
                url: resourceUrl + 'getAllLogs',
                method: 'GET',
                isArray: true
            },
            getProgress: {
                url: resourceUrl + 'getProgress',
                method: 'POST'
            }
        });
    }
})();
