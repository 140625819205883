(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$scope', 'Principal', 'AuthServerProvider', '$state', 'Auth', '$rootScope'];

    function LoginController($scope, Principal, AuthServerProvider, $state, Auth, $rootScope) {
        var vm = this;

        vm.login = login;
        init();

        function init() {
            var token = AuthServerProvider.getToken();
            if (token) {
                Auth.loginWithToken(token, true);
                getAccount();
            }
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (account.userStatus === "ACTIVATED") {
                    $state.go('dashboard');
                    $rootScope.$broadcast('authenticationSuccess');
                } else if (account.userStatus === "NEW") {
                    $state.go('password', {"from": "login"});
                    $rootScope.$broadcast('authenticationSuccess');
                } else if (account.userStatus === "DELETED") {
                    vm.authenticationError = true;
                } else if (account.userStatus === "SUSPENDED") {
                    vm.authenticationError = true;
                }
            });
        }

        function login() {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                getAccount();
                vm.authenticationError = false;
            }).catch(function () {
                vm.authenticationError = true;
            });
        }
    }
})();
