(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('AppLeftMenuController', AppLeftMenuController);

    AppLeftMenuController.$inject = ['$scope', '$rootScope', '$controller', 'Api', '$location', '$localStorage'];

    function AppLeftMenuController($scope, $rootScope, $controller, Api, $location, $localStorage) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.appId = $rootScope.appId;
        vm.appInfo;
        vm.setActiveClass = setActiveClass;

        init();

        function init() {
            $localStorage.appInfo
            Api.applicationInformation({appId: vm.appId}, onSuccess, onFail);
        }

        function onSuccess(appInfo) {
            vm.appInfo = appInfo;
            calculateShownFlag();
        }

        function onFail() {

        }

        function setActiveClass(url) {
            if ($location.url().indexOf(url) > -1) {
                return true;
            }
            return false;
        }

        function calculateShownFlag() {
            var liveClientInfos = vm.appInfo.liveClientInfos;
            if (liveClientInfos && liveClientInfos.length > 0) {
                var version = liveClientInfos.find(function (item) {
                    return item.channel === 'IOS';
                });
                if (version) {
                    vm.showLiveIos = true;
                }
                var version2 = liveClientInfos.find(function (item) {
                    return item.channel === 'ANDROID';
                });
                if (version2) {
                    vm.showLiveAndroid = true;
                }
            }
            var waitingForStoreClientInfos = vm.appInfo.waitingForStoreClientInfos;
            if (waitingForStoreClientInfos && waitingForStoreClientInfos.length > 0) {
                var version3 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'IOS';
                });
                if (version3) {
                    vm.showDraftIos = true;
                }
                var version4 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'ANDROID';
                });
                if (version4) {
                    vm.showDraftAndroid = true;
                }
            }
            vm.showIos = vm.showDraftIos || vm.showLiveIos;
            vm.showAndroid = vm.showDraftAndroid || vm.showLiveAndroid;
        }

    }
})();
