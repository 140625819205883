(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('AppActivityController', AppActivityController);

    AppActivityController.$inject = ['$scope', '$rootScope', '$controller', 'Api', 'Checklist', '$localStorage', '$q', 'User'];

    function AppActivityController($scope, $rootScope, $controller, Api, Checklist, $localStorage, $q, User) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.storeProcessLogs = [];
        vm.checklists = [];
        vm.getUsername = getUsername;
        init();

        function init() {
            vm.appInfo = $localStorage.appInfo;
            vm.appId = $localStorage.appInfo.applicationId;
            Api.taskLogByAppId({appId: vm.appId}, function (response) {
                for (var i = 0; i < response.length; i++) {
                    getUsername(response[i].userId, i).then(function (result) {
                        vm.storeProcessLogs[result.i].username = result.username;
                    });
                }
                var requestId;
                if (response && response.length > 0) {
                    requestId = response[0].storeProcessRequest;
                }
                vm.storeProcessLogs = response;
                Checklist.getAllLogs({taskId: requestId}, function (resp) {
                    for (var j = 0; j < resp.length; j++) {
                        var log = resp[j];
                        log.checklistItem = {};
                        var processLog = vm.storeProcessLogs.find(function (item) {
                            return item.newChannelProcessStatus == log.status && item.channel == log.channel;
                        });
                        if (!processLog.logs) {
                            processLog.logs = [];
                        }
                        var itemIndex = processLog.logs.length;
                        setChecklistItem(log.cheklistItemId, itemIndex, processLog);
                        processLog.logs.push(log);
                    }
                }, function (err) {
                });
            });
        }

        function getUsername(userId, i) {
            var deferred = $q.defer();
            if (userId) {
                User.getUserById({id: userId}, function (resp) {
                    deferred.resolve({username: resp.login, i: i});
                });
            }
            return deferred.promise;
        }

        function setChecklistItem(id, i, processLog) {
            var deferred = $q.defer();
            if (id) {
                Checklist.getById({id: id}, function (checklist) {
                    processLog.logs[i].checklistItem = checklist;
                });
            }
            return deferred.promise;
        }

    }
})();
