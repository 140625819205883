(function () {
    'use strict';

    angular
        .module('consoleApp')
        .factory('ApiToken', ApiToken);

    ApiToken.$inject = ['$resource'];

    function ApiToken($resource) {
        var resourceUrl = 'api/';
        return $resource(resourceUrl, {}, {
            get: {
                url: resourceUrl + 'getApiToken',
                method: 'GET'
            }
        });
    }
})();
