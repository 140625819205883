(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$scope', '$rootScope', '$controller', 'Api', '$state'];

    function DashboardController($scope, $rootScope, $controller, Api, $state) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "dashboard";
        vm.applications = [];
        vm.goDetail = goDetail;

        init();

        function init() {
            getApplications();
        }

        function getApplications() {
            Api.applications({}, onSuccess, onError)
        }

        function onSuccess(response) {
            vm.applications = response;
        }

        function onError() {

        }

        function goDetail(app) {
            $state.go('application', {"appId": app.applicationId});
        }

    }
})();
