(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('TaskController', TaskController);

    TaskController.$inject = ['$scope', '$rootScope', '$controller', 'Api', '$state', 'Checklist', 'User'];

    function TaskController($scope, $rootScope, $controller, Api, $state, Checklist, User) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "tasks";
        vm.notAssignedProcesses = [];
        vm.myProcesses = [];
        vm.allProcesses = [];
        vm.goDetail = goDetail;
        vm.statusByPercentage = {
            INITIAL: 0,
            IN_REVIEW: 25,
            PROCESSING_FOR_STORE: 50,
            WAITING_FOR_STORE: 75,
            REJECTED: 100,
            COMPLETE: 100,
            ON_HOLD: 100
        };

        init();

        function init() {
            getNotAssigned();
            getMyProcess();
        }

        function getNotAssigned() {
            Api.notAssignedProcesses({}, function (response) {
                vm.notAssignedProcesses = response;
                vm.allProcesses = vm.allProcesses.concat(response)
            });
        }

        function getMyProcess() {
            Api.myProcesses({}, onSuccessMyProcess);
        }

        function getUsername(userId, i) {
            if (userId) {
                User.getUserById({id: userId}, function (resp) {
                    vm.allProcesses[i].username = resp.login;
                });
            }
        }

        function onSuccessMyProcess(response) {
            vm.allProcesses = vm.allProcesses.concat(response);
            for (var i = 0; i < vm.allProcesses.length; i++) {
                getUsername(vm.allProcesses[i].assignedUserId, i);
            }

            vm.myProcesses = response;
            var processIds = response.map(function (item) {
                return item.storeProcessRequestId;
            }).sort().filter(function (item, pos, ary) {
                return !pos || item != ary[pos - 1];
            });
            Checklist.getProgress({}, processIds, function (resp) {
                for (var i = 0; i < vm.myProcesses.length; i++) {
                    var myProcess = vm.myProcesses[i];
                    var statusPercentage = vm.statusByPercentage[myProcess.channelProcessStatus];
                    if (statusPercentage && statusPercentage == 100) {
                        myProcess.progress = "100";
                    } else {
                        var isExist = resp[myProcess.storeProcessRequestId];
                        if (isExist) {
                            var percentageByTask = isExist[myProcess.channel];
                            var totalPercentage = percentageByTask != 0 ? (25 * percentageByTask) / 100 + statusPercentage
                                : statusPercentage;
                            myProcess.progress = totalPercentage;
                        } else {
                            if (statusPercentage) {
                                myProcess.progress = statusPercentage;
                            } else {
                                myProcess.progress = "0";
                            }
                        }
                    }

                }
            });

        }

        function goDetail(process) {
            $state.go('application', {"appId": process.applicationId});
        }

    }
})();
