(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$controller', '$scope', '$rootScope', '$timeout', '$state'];

    function PasswordController(Auth, Principal, $controller, $scope, $rootScope, $timeout, $state) {
        var vm = this;
        $controller('AppController', {$scope: $scope});

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function (account) {
            vm.account = account;
        });

        function changePassword() {
            console.log($rootScope.previousState);
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    if ($rootScope.previousState.name === 'home') {
                        $timeout(function () {
                            $state.go('dashboard');
                        }, 1000);
                    }
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
