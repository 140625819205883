(function () {
    'use strict';

    angular
        .module('consoleApp')
        .controller('LiveStoreDetailController', LiveStoreDetailController);

    LiveStoreDetailController.$inject = ['$scope', '$rootScope', '$controller', 'Api', 'appId', 'channel', '$localStorage', 'UploadService'];

    function LiveStoreDetailController($scope, $rootScope, $controller, Api, appId, channel, $localStorage, UploadService) {
        var vm = this;
        $controller('AppController', {$scope: $scope});
        $rootScope.currentMenu = "";
        vm.channel = channel;
        vm.appId = appId;
        $rootScope.appId = vm.appId;
        vm.appInfo;
        vm.appInfoCopy;
        vm.selectedLang;
        vm.selectableLanguage = [];
        vm.selectedLangText = "";
        vm.selectLanguage = selectLanguage;

        init();

        function init() {
            if ($localStorage.appInfo) {
                vm.appInfo = $localStorage.appInfo;
                vm.info = $localStorage.appInfo.liveStoreInfo;
                vm.selectedLang = $localStorage.appInfo.defaultApplicationLanguage.language;
                var clientInfo = $localStorage.appInfo.liveClientInfos.find(function (item) {
                    return item.channel == vm.channel.toUpperCase();
                });
                vm.info.storeVersion = clientInfo.version;
                vm.info.theme = $localStorage.appInfo.themeCode;
                vm.info.packageName = $localStorage.appInfo.bundle;
                UploadService.assets({
                    applicationId: vm.appId,
                    clientVersion: clientInfo.version
                }, onSuccessAssets, onFailAssets);
                calculateShownFlag();
                setLanguages(vm.appInfo.contentLanguages);
                getKeywords();
                vm.loaded = true;
            } else {
                Api.applicationInformation({appId: vm.appId}, onSuccess, onFail);
            }
        }

        function calculateShownFlag() {
            var waitingForStoreClientInfos = vm.appInfo.waitingForStoreClientInfos;
            if (waitingForStoreClientInfos && waitingForStoreClientInfos.length > 0) {
                var version3 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'IOS';
                });
                if (version3) {
                    vm.showIosTaskTab = true;
                }
                var version4 = waitingForStoreClientInfos.find(function (item) {
                    return item.channel === 'ANDROID';
                });
                if (version4) {
                    vm.showAndroidTaskTab = true;
                }
            }
        }

        function onSuccess(appInfo) {
            vm.appInfo = appInfo;
            calculateShownFlag();
            $localStorage.appInfo = appInfo;
            vm.appInfoCopy = angular.copy(vm.appInfo);

            vm.selectedLang = appInfo.defaultApplicationLanguage.language;
            vm.info = appInfo.liveStoreInfo;
            var clientInfo = appInfo.liveClientInfos.find(function (item) {
                return item.channel == vm.channel.toUpperCase();
            });
            vm.info.storeVersion = clientInfo.version;
            vm.info.theme = appInfo.themeCode;
            vm.info.packageName = appInfo.bundle;
            UploadService.assets({
                applicationId: vm.appId,
                clientVersion: clientInfo.version
            }, onSuccessAssets, onFailAssets);
            setLanguages(appInfo.contentLanguages);
            getKeywords();
            vm.loaded = true;
        }

        function setLanguages(languages) {
            var activeLangs = [];
            for (var keyName in vm.info.name) {
                activeLangs.push(keyName);
            }
            for (var i = 0; i < languages.length; i++) {
                var language = languages[i].language;
                if (activeLangs.indexOf(language.language) > -1) {
                    vm.selectableLanguage.push(language);
                }
                if (language.language == vm.selectedLang) {
                    vm.selectedLangText = language.description;
                }
            }
        }

        function onFail() {
            delete $localStorage.appInfo;
        }

        function selectLanguage(lang) {
            setKeywords();
            vm.selectedLang = lang.language;
            vm.selectedLangText = lang.description;
            getKeywords();
        }

        function getKeywords() {
            vm.info.dummyTags = [];
            if (vm.info.keywords) {
                if (vm.info.keywords[vm.selectedLang]) {
                    for (var i = 0; i < vm.info.keywords[vm.selectedLang].length; i++) {
                        vm.info.dummyTags.push({text: vm.info.keywords[vm.selectedLang][i]});
                    }
                } else {
                    vm.info.keywords[vm.selectedLang] = [];
                }
            } else {
                vm.info.keywords = {};
                vm.info.keywords[vm.selectedLang] = [];
            }
        }

        function setKeywords() {
            if (vm.info.dummyTags) {
                vm.info.keywords[vm.selectedLang] = [];
                for (var i = 0; i < vm.info.dummyTags.length; i++) {
                    vm.info.keywords[vm.selectedLang].push(vm.info.dummyTags[i].text);
                }
            }
        }

        function onSuccessAssets(data) {
            vm.images = data;
        }

        function onFailAssets() {

        }

    }
})();
